.my-modal .modal-content {
    background-color: #8367A2;
    color: white;
  }
  
.sidebar .nav-link{
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    color: #000;
    outline: none;
    padding: 5px 10px;
    display: flex;
    align-items: center;
}

.sidebar .nav-link img{
    width: 30px;
    height: 30px;
}
.sidebar .nav-link.active{
    background-color: #8367A2;
    color: #fff;
}
.sidebar .nav-link:hover{
    background-color: #8367A2;
    color: #fff;
}
#example-collapse-text{
    margin-left: 10px;
    margin-right: 10px;
    background-color: #F2EBFC;
}

.btn-close {
    color: #fff;
    background-color: #fff;
}

.sidebar.nav-pills .nav-link.active {
    background-color: #8367A2;
    color: #fff;
}