.Loading_Content {
  display: flex;
  background-color: #cddb2c;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.IsLoadingScreen {
  display: flex;
  background-color: #cddb2c;
  height: '100%';
  width: '100%';
  align-items: center;
  justify-content: center;
}

.IconImage {
  width: 100px;
  height: 100px;
  position: absolute;
  transform: translate(-505, -50%);
  width: 100px;
  height: 100px;

  box-sizing: border-box;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes IconImage {
  0% {
    -webkit-transform: scale(2);
  }
  50% {
    -webkit-transform: scale(2.1);
  }
  100% {
    -webkit-transform: scale(2);
  }
}

@keyframes IconImage {
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(2.1);
  }
  100% {
    transform: scale(2);
  }
}

.IconImage {
  -webkit-animation-name: IconImage;
  animation-name: IconImage;
}

.Loading_Text {
  font-family: 'Poppins', sans-serif !important;
  font-size: 4em;
  color: #fff;
  font-weight: bold;
  text-align: center;
  z-index: 1;
  text-align: center;
  position: absolute;
  transform: translate(-505, -50%);
  width: 100%;
  background: #8367a2;
  bottom: 15%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 768px) {
  .Loading_Text {
    top: 60%;
    font-size: 1.5rem;
  }
}

.spanText {
  font-weight: lighter;
}
