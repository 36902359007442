.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.ad {
    height: auto;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 8px 0px;
    border-radius: 5px;
    align-items: center;
    margin: 15px 0px;
    margin-top: 0;
    animation: pulse 1s linear infinite;
    border-radius: 20px;
    transition:all 2s ease-out;
    transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale(1);
}

/* .ad:hover {
    transform: perspective(500px) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale(1.1);
    transition:all 1s ease-out;
    margin-top: 10px;
} */
