.formInformation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
}

.firstInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
}
.special_modal .modal_content {
    background-color: #000;
  }
 .nav-link {
    margin-top: 15px;
    margin-left: 10px;
    border-radius: 10px;
}

.nav-ink.active{
    background-color: #8367A2;
    color: #fff;
}
.sidebarBg{
    background-color: #fff;
    padding: 0px 0px 10px;
    margin: 8px 0px;
    border-radius: 10px;
    box-shadow: 0px 6px 6px #dee3a4;
}
